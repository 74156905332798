// Material Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

// Styles
import "./styles.css";

// Assets
import logo from "../../assets/logo.png";

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { api } from "../../service/api";

export const Header = () => {
  const [modalActive, setModalActive] = useState(true);
  const [menuActiveTwo, setModalMenuActive] = useState(true);
  const [menuActive, setMenuActive] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get("categorias");

        setData(response.data);
      } catch (error) {
        throw error;
      }
    }

    loadData();
  }, [data]);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const handleActiveModal = () => {
    setModalActive(!modalActive);
  };

  const handleMenuActiveTwo = () => {
    setModalMenuActive(!menuActiveTwo);
  };


  const units = [
    {
      id: '1',
      link: 'https://www.33doctoraparecida.com.br/',
      type: 'Aparecida de Goiânia - GO'
    },
    {
      id: '2',
      link: 'https://www.33doctorbc.com.br/',
      type: 'Balneário Camboriú - SC'
    },
    {
      id: '3',
      link: 'https://www.33doctorgoytacazes.com.br',
      type: 'Campos dos Goytacazes - RJ'
    },
    {
      id: '4',
      link: 'https://www.33doctorcanoas.com.br',
      type: 'Canoas - RS'
    },
    {
      id: '5',
      link: 'https://www.33doctorcuritiba.com.br/',
      type: 'Curitiba - PR'
    },
    {
      id: '6',
      link: 'https://www.33doctorfeiradesantana.com.br/',
      type: 'Feira de Santana - BA'
    },
    {
      id: '7',
      link: 'https://www.33doctorfazendariogrande.com.br',
      type: 'Fazenda Rio Grande - PR'
    },
    {
      id: '8',
      link: 'https://www.33doctorflorianopolis.com.br/',
      type: 'Florianópolis - SC'
    },
    {
      id: '9',
      link: 'https://agendar.33doctoronline.com.br',
      type: 'Goiânia - GO'
    },
    {
      id: '10',
      link: 'https://www.33doctorguarapari.com.br',
      type: 'Guarapari - ES'
    },
    {
      id: '11',
      link: 'https://agendar.33doctormaringa.com.br',
      type: 'Maringa - PR'
    },
    {
      id: '12',
      link: 'https://agendar.33doctornatal.com.br',
      type: 'Natal - RN'
    },
    {
      id: '13',
      link: 'https://www.33doctorportoalegre.com.br',
      type: 'Porto Alegre - RS'
    },
    {
      id: '14',
      link: 'https://www.33doctorpvh.com.br',
      type: 'Porto Velho - RO'
    },
    {
      id: '15',
      link: 'https://www.33doctorsjp.com.br/',
      type: 'São José dos Pinhais - PR'
    },
    {
      id: '16',
      link: 'https://www.33doctorsaopaulo.com.br/',
      type: 'São Paulo - SP'
    },
    {
      id: '17',
      link: 'https://www.33doctorvilavelha.com.br/',
      type: 'Vila Velha - ES'
    },
    {
      id: '18',
      link: 'https://www.33doctorvitoria.com.br/',
      type: 'Vitória - ES'
    },

];

units.sort((a, b) => a.type.localeCompare(b.type));
console.log(units);


  return (
    <header className="header-container">
      <div className="left-side">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <div className="right-side">
        <nav>
          <ul id="menu-icon">
            <MenuIcon
              onClick={handleActiveModal}
              className={modalActive ? "" : "hidden"}
            />
          </ul>

          <ul className={modalActive ? "" : "active"}>
            <CloseIcon
              onClick={handleActiveModal}
              className={modalActive ? "hidden" : "showed"}
            />
            <li>
              <a href="/#about" onClick={handleActiveModal}>
                Como funciona
              </a>
            </li>
            <li>
              <Link to="/agendamentos/Especialidades" onClick={handleActiveModal}>
                Consultas
              </Link>
            </li>
            <li>
              <a href="#click" onClick={handleMenuActive} id="icon">
                Exames
                {menuActive ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </a>
              <div className="menu" id={menuActive ? "" : "active"}>
                {data.map((item: any) => (
                  <Link
                    to={`/agendamentos/${item?.type.replace(/\s+/g, "-")}`}
                    onClick={handleActiveModal}
                  >
                    <span  onClick={handleMenuActive}>
                      {item?.type}
                    </span>
                  </Link>
                ))}
              </div>
            </li>

            <li>
              <a href="#click" onClick={handleMenuActiveTwo} id="icon">
                Unidades
                {menuActiveTwo ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </a>
              <div className="menu" id={menuActiveTwo ? "" : "active"}>
                {units.map((item: any) => (
                  <Link
                    to={item.link}
                    onClick={handleActiveModal}
                    target="_blank"
                  >
                    <span  onClick={handleMenuActiveTwo}>
                    {item.type}
                    </span>
                  </Link>
                ))}
              </div>
            </li>
            <li>
              <a href="https://blog33doctor.com.br/" target="_blank">
                Acesse nosso <strong className="blog"><table>Blog</table></strong>
              </a>
            </li>
            <li>
              <a href="https://telemedicina33doctor.com.br/" target="_blank">
                <strong className="blog"><table>Telemedicina</table></strong>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};
